<template>
  <form action="">
      <label for="from">
        <p>C</p>
        <input type="date" name="" id="from" v-model="from" @change="SetFilter()">
      </label>
      <label for="to">
        <p>По</p>
        <input type="date" name="" id="to" v-model="to" @change="SetFilter()">
      </label>
  </form>
  <div class="stats">
      <div class="stats-item">
        <p class="num">{{ getMoney(money) }} </p>
        <p>Пополнение</p>
      </div>
      <div class="stats-item">
        <p class="num">{{ enter }}</p>
        <p>Заходов на сайт</p>
      </div>
      <div class="stats-item">
        <p class="num">{{ viwe }}</p>
        <p>Просмотров анкет</p>
      </div>
      <div class="stats-item">
        <p class="num">{{ phone }}</p>
        <p>Телефон</p>
      </div>
      <div class="stats-item">
        <p class="num">{{ whatsapp }}</p>
        <p>WhatsApp</p>
      </div>
      <div class="stats-item">
        <p class="num">{{ telegram }}</p>
        <p>Telegram</p>
      </div>
      <div class="stats-item">
        <p class="num">{{ register }}</p>
        <p>Регистраций </p>
      </div>
  </div>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios'
export default {
  name: 'App',
  data() {
    return {
      money: '',
      enter: '',
      viwe: '',
      phone: '',
      whatsapp: '',
      telegram: '',
      register: '',
      to: '',
      from: '',
    }
  },
  mounted() {
    this.GetStats(`date_field=${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`)
  },
  methods: {
    SetFilter() {
      if (this.to && this.from) {
        this.GetStats(`date_field_from=${this.from}&date_field_to=${this.to}`)
      }
    },
    GetStats(filt) {
      console.log(filt)
      // axios( `https://api.v-ankete.com/api/analytic/analytic_admin/?status=ENTER&${filt}`).then(response => this.enter = response.data.count)

      axios(`https://api.v-ankete.com/api/analytic/analytic_admin/?status=ENTER&${filt}`).then(response => (this.enter = response.data.count)).catch(error => console.log(error));
      axios(`https://api.v-ankete.com/api/analytic/analytic_admin/?status=MASSAGER&${filt}`).then(response => (this.viwe = response.data.count)).catch(error => console.log(error));
      axios(`https://api.v-ankete.com/api/analytic/analytic_admin/?status=PHONE&${filt}`).then(response => (this.phone = response.data.count)).catch(error => console.log(error));
      axios(`https://api.v-ankete.com/api/analytic/analytic_admin/?status=WHATSAPP&${filt}`).then(response => (this.whatsapp = response.data.count)).catch(error => console.log(error));
      axios(`https://api.v-ankete.com/api/analytic/analytic_admin/?status=TELEGRAM&${filt}`).then(response => (this.telegram = response.data.count)).catch(error => console.log(error));

      axios(`https://api.v-ankete.com/api/analytic/transactions/?limit=10000&${filt}`).then(response => (this.money = response.data));
      axios(`https://api.v-ankete.com/api/analytic/worksheets/?${filt}`).then(response => (this.register = response.data.count)).catch(error => console.log(error));
    },
    getMoney(money) {
      let sum = 0
      if (money.results) {
        money.results.forEach(e => {
          sum = sum + e.summ
        });
      }
       
     
      return sum
    }
  },
  components: {
    // HelloWorld
  }
}
</script>


